import React, { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"

import "../../styles/pages/glasses.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlassesHeading from "../../components/GlassesHeading"
import Breadcrumbs from "../../components/Breadcrumbs"
import GlassesFilter from "../../components/GlassesFilter"
import GlassesList from "../../components/GlassesList"
import ProductBanner from "../../components/ProductBanner"
import { useDebounce, useGlasses } from "../../services/hooks"
import { navigateWithFilters } from "../../services/utils"
import CartContext from "../../context/cartContext"

const Glasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern")
      )
    }
  )


  const right_side_text = "Choosing your personal eyeglasses style can be exciting, yet overwhelming. We’ve got you covered with our extensive selection of eyeglasses filtered by frame shape, making it easy for you to find the exact frames that are calling your name. With options including round, square, rectangular, cat eye and more, we’re confident that you’ll discover the perfect pair. Plus, all of our different types of glasses frames have impact-resistant lenses, durable barrel hinges, and padded temple tips for extra cush. Look good, see better—and feel great with whatever eyeglasses style you choose."
  const list_items = [
    "Premium, natural fiber acetate frames that are stronger, more durable, and last longer than plastic frames.",
    "Anti-reflective, anti-glare, anti-scratch, hydrophobic, and oleophobic coatings.",
    "Optional blue-light filtering on all glasses frames.",
    "Hand-assembled and made to order.",
    "The perfect pair for every person: visit our resources library to learn more about <a href='https://hubblecontacts.com/blog/glasses-frame-shapes-guide-from-classics-to-unique-picks'>glasses frame shapes</a>, from classic to unique picks.",
  ]

  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: [],
    shape: [],
    material: [],
    color: [],
    gender: [],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if (!debouncedFilter) return

    navigateWithFilters(location.pathname, debouncedFilter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilter])

  useEffect(() => {
    setHeaderColor("orange")

    return () => {
      setHeaderColor("black")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: sortedGlasses.map(( product, index ) => (
            {
              index: index,
              item_id: product.shopifyProductId,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'optical',
              item_category3: product.productGender,
              item_category4: product.material,
              item_category5: product.size,
              item_list_id: `${product.productGender}-Optical-By-Frame`,
              price: parseFloat(product.glassesBasePrice),
            }
          ))
        }
      })
    }
  }, [sortedGlasses])

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/shop-by-frame-shape",
      "@type": "webpage",
      "name": "Shop the Best Glasses and Sunglasses by Frame Shape | Hubble",
      "description": "Explore our collection of glasses and sunglasses available in a wide variety of frame shapes. Find your perfect pair of stylish new glasses in your choice of frame style, including round, square, and more!",
      "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
      }
      }
  ]



  return (
    <Layout className="glasses">
      <SEO
        title="Shop the Best Glasses by Frame Shape"
        description="Explore our collection of glasses available in a wide variety of frame shapes. Find your perfect pair of stylish new glasses in your choice of frame style, including round, square, and more!"
        schemaMarkup={schemaMarkup}
        />
      <GlassesHeading
        color="orange"
        title="Shop Eyeglasses by Style"
        description="Find the style that’s right for you. Shop all of our glasses styles—from cat eye and
          round to square retro styles and filter by type of glasses frame. Starting at just $49.99."
      />
      <div className="glasses-container">
        <div className="grid-section">
          <Breadcrumbs
            color="orange"
            links={[{ to: "/", label: "Homepage" }, { label: "Eyeglasses" }]}
          />
          <GlassesFilter
            type="glasses"
            glassesLength={sortedGlasses.length}
            filter={filter}
            setFilter={setFilter}
          />
          <GlassesList
            type="glasses"
            glasses={sortedGlasses}
            color={filter.color}
            gender={filter.gender[0]}
          />
          <ProductBanner
            title={"Glasses frames styles starting at just $49.99."}
            right_side_text={right_side_text}
            list_items={list_items}
            optional_image="Hubble-Glasses-PCP-Inset-Lifestyle-Image.jpg"
            optional_image_alt_text="Woman wearing Hubble eyeglasses while enjoing time at home"
            color="orange"
          />
        </div>
      </div>
    </Layout>
  )
}

export default Glasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
